.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.lms-mr-1 {
  margin-right: 1em;
}
.lms-mr-1 {
  margin-right: 1em;
}
.text_center {
  text-align: center;
}
.text-between {
  display: flex;
  justify-content: space-between;
}

.lms-mb-4 {
  margin: 0 0 4px;
}
.lms-mb-6 {
  margin: 0 0 6px;
}
.lms-mb-8 {
  margin: 0 0 8px;
}
.lms-mb-10 {
  margin: 0 0 10px;
}
.lms-mb-12 {
  margin: 0 0 12px;
}
.lms-mb-14 {
  margin: 0 0 14px;
}
.lms-mb-16 {
  margin: 0 0 16px;
}
.lms-mb-18 {
  margin: 0 0 18px;
}
.lms-mb-20 {
  margin: 0 0 20px;
}
.lms-mb-22 {
  margin: 0 0 22px;
}
.lms-mb-24 {
  margin: 0 0 24px;
}
.lms-mb-26 {
  margin: 0 0 26px;
}
.lms-mb-28 {
  margin: 0 0 28px;
}
.lms-mb-30 {
  margin: 0 0 30px;
}
.lms-mb-32 {
  margin: 0 0 32px;
}

.lms-mb-40 {
  margin: 0 0 40px;
}

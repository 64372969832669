.wrap {
  :global {
    .ant-select {
      overflow: hidden;
      border-radius: 27px;
    }

    .ant-select-selector {
      padding: 4px 6px;
    }

    .ant-select-selection-overflow {
      max-height: 40px;
      overflow-y: scroll;
    }

    .ant-table-wrapper tfoot > tr > td {
      padding: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(179, 179, 179);
      border-radius: 5px;
    }
  }
}

.hide {
  display: none;
}

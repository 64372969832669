.title {
  color: white;
  font-size: 30px;
  width: 306px;
  background: #f7758a;
  height: 55px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

li {
  &:last-child {
    span.ant-breadcrulms-mb-link {
      color: #f7758a;
    }
  }
}

.active-link {
  color: #f7758a !important;
}

.inactive-link {
  color: inherit !important;
}

.icon-width {
  width: 23px;
}

.margin-link {
  margin-left: 10px;
}

.pink-bread {
  color: #f7758a;
}

.custom-modal {
  width: 100% !important;

  .ant-modal-header {
    background: #b1b1b1;
    text-align: center;
    border-radius: 20px 20px 0 0;
    margin: 0;

    .ant-modal-title {
      font-size: 30px;
      color: white;
      font-weight: 800;
    }
  }

  .ant-modal-body,
  .ant-modal-footer,
  .ant-modal-header {
    padding: 20px 24px;
  }

  .ant-modal-body {
    padding: 20px 60px 20px;
  }

  .ant-modal-footer {
    text-align: center;
    display: flex;

    button {
      width: 100%;
      color: white;
    }

    .ant-btn-default {
      background: #707070;

      &:hover {
        color: unset;
        border-color: unset;
      }
    }

    .ant-btn-primary {
      background: #f7758a;
    }
  }

  .ant-modal-content {
    padding: 0px;
    border-radius: 20px;
    margin: auto;
    width: 100%;
    max-width: 825px;
  }
}

.hideFooter .ant-modal-footer {
  display: none !important;
  pointer-events: none !important;
}

.overallResults {
  :global {
    .table-custom {
      .ant-table {
        .ant-table-container {
          .ant-table-header {
            table {
              thead {
                tr:first-child {
                  th:first-child {
                    color: #ffffff !important;
                    background-color: #f7758a;
                    span {
                      font-size: 32px;
                      margin-right: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .wrapTable {
    margin-bottom: 51px;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(179, 179, 179);
      border-radius: 5px;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  height: 40px;
  padding: 12px 32px;
  border-radius: 27px;
  outline: none;
  border: none;
}

.btnPrint {
  gap: 10px;
  background-color: #e3e3e3;

  span {
    color: var(--neutral-2) !important;
  }

  img {
    max-width: unset;
    max-height: unset;
  }
}

@import '../../../../styles/mixin';

.modal {
  :global {
    .ant-modal-content {
      min-width: 1200px;
      .ant-modal-header {
        background: #ffffff;
        .ant-modal-title {
          color: #000000;
        }
      }
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.mypage {
  background: url(../../../../assets/images/StudentStatistic/mypage_pc.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // min-height: calc(100vh - 500px);
  min-width: 700px;
  border-radius: 16px;
  padding: 20px;
  color: #595757;
  margin: 0 auto;
  .top {
    display: flex;
  }
  &__subjects {
    margin-left: 64px;
    table {
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      margin: auto;
      min-width: 260px;
      height: 500px;
      padding-top: 10px;
      tr {
        &:last-child {
          td {
            padding-bottom: 5px;
            height: 22px;
          }
        }
      }
      th {
        font-size: 14px;
        background-color: #fff;
        padding: 16px;
        background-color: #e1e1e1;
        border-left: 3px solid #e4f7e6;
        &:first-child {
          padding: 0 10px;
          background-color: rgba(255, 255, 255, 0.65);
          text-align: left;
        }
        &:last-child {
          padding-top: 6px;
          @include mobile {
            border: none;
          }
        }
      }
      td {
        background-color: #fff;
        padding: 8px;
        border-left: 3px solid #e4f7e6;
        &:first-child {
          background-color: rgba(255, 255, 255, 0.65);
          color: #a5a072;
          font-size: 16px;
          text-align: left;
          white-space: nowrap;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:last-child {
          background-color: rgba(255, 255, 255, 1);
          padding: 0 10px 0 8px;
          @include mobile {
            border: none;
          }
        }
      }
    }
  }

  &__result {
    table {
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 3px;
      min-width: 600px;
      tr {
        &:last-child {
          td {
            padding-bottom: 5px;
            height: 22px;
          }
        }
      }
      td {
        &:first-child {
          color: #a5a072;
          font-size: 18px;
          padding-top: 10px;
          text-align: center;
          white-space: nowrap;
          width: 58%;
        }
        &:last-child {
          padding: 0 10px 0 8px;
          border-left: 3px solid #e4f7e6;
          @include mobile {
            border: none;
          }
        }
      }
    }
  }

  @include mobile {
    min-height: calc(100vh - 240px);
    background: #fff;
  }
}

.btnPrint {
  position: absolute;
  right: 24px;
  top: 20px;
  background-color: var(--neutral-3);
  gap: 10px;
  min-width: initial;
  height: 50px;
  padding: 12px 32px;
  outline: none;
  border: none;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--neutral-2) !important;
    font-size: 16px;
  }

  img {
    max-width: unset;
    max-height: unset;
  }
}

.btnClose {
  background-color: rgba(0, 0, 0, 0.16);
  color: #ffffff;
  padding: 0 32px;
  line-height: 24px;
  border-radius: 20px;
  border: 2px solid #ffffff;
}

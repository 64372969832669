@import './reset.scss';
@import './custom-antd';
@import './color';
@import './space';
@import './text';
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: 'Noto Sans JP', sans-serif, 'Segoe UI';
  line-height: normal;
  text-rendering: optimizeSpeed;
  text-shadow: 1px 3px 1px rgba(0, 0, 0, 0.01);
}

html {
  min-height: -webkit-fill-available;
}

body {
  overflow-y: overlay;
  overflow-x: hidden;
  min-height: -webkit-fill-available;

  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }
}

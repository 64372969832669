.ant-row .ant-form-item {
  width: 100%;
}
// .ant-form-item {
//   margin: 0;
// }
.ant-form-item label {
  font-size: 16px;
  font-weight: bold;
  color: black;

  &::before {
    display: none !important;
  }
}
.ant-input-affix-wrapper {
  border-radius: 27px !important;

  :hover {
    border: unset !important;
    box-shadow: none;
  }

  :focus {
    border: unset !important;
    box-shadow: none;
  }
}

.ant-input-group .ant-input-group-addon,
.ant-input-group .ant-input-group-wrap {
  width: 0;
}

.ant-input-group-wrapper .ant-input-search .ant-input-search-with-button {
  height: 100% !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border: 1px solid #d9d9d9;
}

:where(.css-dev-only-do-not-override-ixblex).ant-btn-default:not(:disabled):hover {
  border-color: #d9d9d9;
}

:where(.css-dev-only-do-not-override-ixblex).ant-input-group-wrapper {
  height: 100%;
}

.ant-select-selector,
.ant-input {
  border-radius: 27px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.ant-btn {
  height: 40px;
}

.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item-control-input-content {
  textarea {
    height: unset !important;
  }
}

.ant-form-item .ant-form-item-feedback-icon-success,
.ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
  display: none;
}

.ant-input-affix-wrapper {
  padding: 0;
}

.ant-input-suffix {
  width: 22px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 11px;
}

.ant-modal-header {
  background: #b1b1b1;
  text-align: center;
  border-radius: 20px 20px 0 0;
  margin-bottom: 0;

  .ant-modal-title {
    font-size: 30px;
    color: white;
    font-weight: 800;
  }
}

.ant-modal-content {
  padding: 0px;
  border-radius: 20px;
}

.ant-table-cell::before {
  display: none;
}

.ant-table-cell.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: unset;
}

.ant-table-wrapper {
  .ant-pagination {
    justify-content: center !important;
    padding: 0;
    .ant-pagination-jump-next {
      display: flex;
      align-items: center;
    }
  }

  .ant-table-cell {
    border-inline-end: 1px solid #f0f0f0;
    vertical-align: middle;
  }

  .ant-table-thead .ant-table-cell {
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }

  .ant-table-thead .ant-table-selection-column {
    padding: 16px;
  }
}

.ant-pagination {
  li.ant-pagination-item {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.ant-table-thead {
  th {
    color: #f7758a !important;
    font-size: 16px !important;
  }
}
.ant-pagination {
  text-align: center;
  padding: 20px 0;

  .ant-pagination-options {
    display: none;
  }
}

.ant-picker-ok {
  button {
    background-color: #f7758a !important;
  }
}

.ant-table-filter-dropdown-btns {
  .ant-btn-primary {
    background-color: #f7758a !important;
    border-color: #f7758a !important;
  }
}

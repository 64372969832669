.wrapSelect {
  justify-content: space-between;
  :global {
    // .ant-select-selector {
    //   height: 46px !important;
    // }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  height: 40px;
  padding: 12px 32px;
  border-radius: 27px;
  outline: none;
  border: none;
}

.btnFilter {
  background-color: var(--primary-1) !important;
  color: #fff;
}

.btnPrint {
  gap: 10px;
  background-color: #e3e3e3;

  span {
    color: var(--neutral-2) !important;
  }

  img {
    max-width: unset;
    max-height: unset;
  }
}

.wrap {
  :global {
    .ant-table-thead > tr:first-child > .ant-table-cell:first-child {
      background-color: #f7758a;
      color: #fff !important;
    }

    .ant-select {
      overflow: hidden;
      border-radius: 27px;
    }

    .ant-select-selector {
      padding: 4px 6px;
    }

    .ant-select-selection-overflow {
      max-height: 40px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(179, 179, 179);
      border-radius: 5px;
    }
  }
}

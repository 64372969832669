.navbar {
  // max-width: 2600px;
  margin: 0 auto;
  width: 100%;

  .navbar-left {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 150px;
      height: 61px;
    }
  }

  &-right {
    display: flex;
    justify-content: end;
    padding-right: 24px;
  }

  .btn-logout {
    background: white;
    padding: 5px 22px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.header_nav {
  height: 80px;
  width: 100%;
  background: url('../../../../public/images/kanri_hd.png') no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    padding: 10px;
  }
}

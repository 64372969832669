.wrap {
  :global {
    .ant-select {
      overflow: hidden;
      border-radius: 27px;
    }

    .ant-select-selector {
      padding: 4px 6px;
    }

    .ant-select-selection-overflow {
      max-height: 40px;
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(179, 179, 179);
      border-radius: 5px;
    }
  }
}

.btnDetail {
  background: gray;
  padding: 10px;
  border-radius: 8px;
  color: white;
}

.filterWrap {
  display: flex;
  column-gap: 24px;
  :global {
    .ant-form-item {
      margin: 0;
    }

    // .ant-select-selector {
    //   height: 46px !important;
    // }
  }
  .btnSubmit {
    background: #f7758a;
  }
  .rowInput {
    > div {
      width: 300px;
    }
  }

  > div {
    margin: 0 0 10px;
  }

  > div:nth-child(2) {
    :global {
      .ant-select {
        min-width: 100px;
      }
    }
  }
}

.searchBox {
  .btnSubmit {
    gap: 10px;
    min-width: initial;
    padding: 12px 32px;
    outline: none;
    border: none;
    background-color: var(--primary-1);
  }
}

.printBox {
  .btnPrint {
    background-color: var(--neutral-3);
    gap: 10px;
    min-width: initial;
    padding: 12px 32px;
    outline: none;
    border: none;

    span {
      color: var(--neutral-2) !important;
    }

    svg {
      max-width: 32px;
    }
  }
}
